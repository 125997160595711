import "./skip-link-focus-fix.js";
import "script-loader!slick-carousel";
import Cookies from "js-cookie";
import fancybox from '@fancyapps/fancybox';

(function ($) {
  $.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop() - 150;
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  $(document).ready(function () {
    headerEffects();
    inputEffects();
    menuToggle();
    pageHeaderScroll();
    searchToggle();
    accordionBlock();
    teamMembers();
    accordionShortcode();
    imageTextBlock();
    featuredBlogPosts();
    intercomCookie();
    alertBanner();
    careerAccordions();
    careerTestimonial();
    careerButton();

    $(window).on("scroll", function () {
      headerEffects();
      animatedButtons();
    });

    $(window).on("resize", function () {
      animatedButtons();
    });

    $(window).scroll();
  });

  // Header scroll effects
  function headerEffects() {
    var navbar = $("#masthead");

    var scrollPosition = $(window).scrollTop();

    if (scrollPosition > 100) navbar.addClass("site-header--scrolled");
    else navbar.removeClass("site-header--scrolled");
  }

  // Header menu toggle
  function menuToggle() {
    $("#menu-toggle").on("click", function () {
      $(this).toggleClass("site-header__menu-toggle--open");
      $("#menu-menu-1").toggleClass("site-header__menu--open");
    });

    $(".submenu-toggle").on("click", function () {
      var submenu = $(this).next(".sub-menu");
      $(this).toggleClass("submenu-toggle--open");
      submenu.slideToggle();
    });
  }

  // Form input label effect
  function inputEffects() {
    $(
      ".site-footer .ginput_container input[type=text], .ginput_container input[type=email], .ginput_container input[type=number], .ginput_container textarea"
    )
      .focus(function () {
        var label = $(this).parent().prev(".gfield_label");
        label.addClass("gfield_label--focus");
      })
      .blur(function () {
        if (!$(this).val()) {
          var label = $(this).parent().prev(".gfield_label");
          label.removeClass("gfield_label--focus");
        }
      });

    $(document).bind("gform_post_render", function () {
      inputEffects();
      $(
        ".site-footer .ginput_container input[type=text], .ginput_container input[type=email], .ginput_container input[type=number], .ginput_container textarea"
      ).each(function () {
        if ($(this).val()) {
          var label = $(this).parent().prev(".gfield_label");
          label.addClass("gfield_label--focus");
        }
      });
    });
  }

  // Page header scroll button
  function pageHeaderScroll() {
    $("#page-header-scroll").on("click", function () {
      var navbarHeight = $("#masthead").outerHeight(true);
      var pageHeaderHeight =
        $("#page-header").outerHeight(true) + $("#page-header").position().top;

      $("body,html").animate(
        {
          scrollTop: pageHeaderHeight - navbarHeight,
        },
        400
      );
    });
  }

  // Header search toggle
  function searchToggle() {
    var header = $("#masthead");

    $("#search-toggle").on("click", function () {
      header.toggleClass("site-header--search-open");
      $("#header-search").focus();
    });

    $("body").on("click", function (event) {
      if (!$(event.target).is("#search-toggle, #header-search")) {
        header.removeClass("site-header--search-open");
      }
    });
  }

  // Accordion block
  function accordionBlock() {
    $(
      ".accordion-block__toggle, .accordion-block__learn-more, .accordion-block__heading"
    ).on("click", function (e) {
      e.preventDefault();

      var accordion = $(this).closest(".accordion-block__accordion"),
        learnMore = accordion.find(".accordion-block__learn-more"),
        content = accordion.find(".accordion-block__content");

      content.slideToggle();
      learnMore.slideToggle();
      accordion.toggleClass("accordion-block__accordion--open");
    });
  }

  // Team member block popups
  function teamMembers() {
    $(".team-members__item").on("click", function (e) {
      e.preventDefault();

      var memberID = $(this).data("team-member"),
        popup = $('.team-members__popup[data-team-member="' + memberID + '"]'),
        slider = popup.find(".team-members__popup-slider");

      popup.addClass("team-members__popup--show");

      slider.slick("setPosition");
      slider.slick("slickPlay");
    });

    $(".team-members__popup-overlay, .team-members__popup-close").on(
      "click",
      function () {
        var popup = $(this).closest(".team-members__popup"),
          slider = popup.find(".team-members__popup-slider");

        slider.slick("slickPause");
        popup.removeClass("team-members__popup--show");
      }
    );

    $(".team-members__popup-slider").each(function () {
      $(this).slick({
        speed: 300,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
      });
    });
  }

  // Accordion shortcode
  function accordionShortcode() {
    $(".accordion-shortcode__title").on("click", function () {
      var accordion = $(this).parent(".accordion-shortcode"),
        content = $(this).next(".accordion-shortcode__content");

      content.slideToggle();
      accordion.toggleClass("accordion-shortcode--open");
    });
  }

  // Image / Text block
  function imageTextBlock() {
    $(".image-text__video-overlay").on("click", function () {
      var video = $(this).find(".image-text__video");
      video[0].src += "?autoplay=1";
      video.show();
    });
  }

  // Animated buttons
  function animatedButtons() {
    $(".hm-button--animated").each(function () {
      if ($(this).isInViewport()) {
        $(this).addClass("scrolled");
      }
    });
  }

  // Featured blog posts
  function featuredBlogPosts() {
    $("#featured-posts")
      .on("afterChange init", function (event, slick) {
        var currentSlide =
            event.type == "init" ? 0 : $(this).slick("slickCurrentSlide"),
          secondSlideID,
          thirdSlideID;

        if (currentSlide == 0) {
          secondSlideID = 1;
          thirdSlideID = 2;
        } else if (currentSlide == 1) {
          secondSlideID = 2;
          thirdSlideID = 0;
        } else {
          secondSlideID = 0;
          thirdSlideID = 1;
        }

        var secondSlide = $("#featured-post-" + secondSlideID),
          thirdSlide = $("#featured-post-" + thirdSlideID),
          secondTab = $("#featured-tab-second"),
          thirdTab = $("#featured-tab-third");

        secondTab.attr("href", secondSlide.attr("href"));
        thirdTab.attr("href", thirdSlide.attr("href"));

        secondTab
          .find(".featured-posts__tab-title")
          .text(secondSlide.data("title"));
        thirdTab
          .find(".featured-posts__tab-title")
          .text(thirdSlide.data("title"));

        secondTab
          .find(".featured-posts__tab-meta")
          .html(secondSlide.data("meta"));
        thirdTab
          .find(".featured-posts__tab-meta")
          .html(thirdSlide.data("meta"));
      })
      .slick({
        speed: 500,
        autoplay: true,
        autoplaySpeed: 7000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
        nextArrow:
          '<button class="slick-next slick-arrow" aria-label="Next"><i class="fa fa-chevron-right"></i></button>',
        prevArrow:
          '<button class="slick-prev slick-arrow" aria-label="Previous"><i class="fa fa-chevron-left"></i></button>',
      });
  }

  // Use cookie to determine whether to show intercom chat
  function intercomCookie() {
    var cookie = Cookies.get("intercom-shown");

    if (!cookie || cookie != 1) {
      setTimeout(function () {
        Intercom("show");
      }, 5000);
      Cookies.set("intercom-shown", 1, { expires: 7 });
    }
  }

  // Site alert banner
  function alertBanner() {
    var alertBanner = $("#alert-banner");
    if (alertBanner.length) {
      var cookie = Cookies.get("alert-shown");

      if (!cookie || cookie != 1) {
        alertBanner.show();

        var headerHeight = $("#masthead").height();
        $("#page").css("padding-top", headerHeight);
      }

      $("#alert-close").on("click", function (e) {
        e.preventDefault();
        alertBanner.hide();
        var headerHeight = $("#masthead").height();
        $("#page").css("padding-top", headerHeight);
        Cookies.set("alert-shown", 1, { expires: 7 });
      });
    }
  }

  // Career Accordions
  function careerAccordions() {
    $('.careers-accordions__accordion--toggle').on('click', function(e) {
      $(this).siblings('.careers-accordions__accordion--content').toggleClass('career-accordion-active');
      $(this).children('.careers-accordions__accordion--header').children('.careers-accordions__accordion--header-triangle').toggleClass('career-accordion-active');
    })
  }

  // Careers Testimonial Slider
  function careerTestimonial() {
    $('.careers-testimonial__slider').slick({
      prevArrow: $('.careers-testimonial__slider--buttons-prev'),
      nextArrow: $('.careers-testimonial__slider--buttons-next'),
      adaptiveHeight: true
    })
  }

  // Single Careers Application Button
  function careerButton() {
    const application = document.querySelector('.single-career__application');

    if (application) {
      const submitButton = application.querySelector('.gform_button');

      if (submitButton) {
        submitButton.classList.add('hm-button')
      }
    }
  }
})(jQuery);
